import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { IUserDataDto } from '@shared/interfaces/user-data.interface';
import { environment } from '@env/environment';
import { FrontLineModel } from '@modules/user-management/models';
import frontlinesData from '@assets/json/frontline.json';
import { delay, map } from 'rxjs/operators';
import { IFrontLine } from '@modules/user-management/interfaces';

const ENDPOINT = environment.api;

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private http: HttpClient = inject(HttpClient);
    private snackBar: SnackBarService = inject(SnackBarService);

    getUserData() {
        // const errorResponse = {
        //    status: 403,
        //    message: 'Unauthorized'
        // };
        //
        // return throwError(errorResponse);
        return this.http.get<IUserDataDto>(ENDPOINT + '/me');
    }

    getFrontLines(): Observable<FrontLineModel[]> {
        return of(frontlinesData.frontline).pipe(
            map(fronlines => {
                return fronlines.map((f: IFrontLine) => new FrontLineModel(f));
            }),
            delay(100),
        );
    }

    decodeErrorMessage(message: string): string {
        try {
            const errorObject = JSON.parse(message.substring(message.indexOf('{')));

            return errorObject.message || '';
        } catch (error) {
            return message;
        }
    }

    handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            if (error.status === 403) this.snackBar.error('acm_forbidden');
            else if ([500, 503].indexOf(error.status) > -1)
                this.snackBar.error('acm_service_not_available');

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}

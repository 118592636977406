@if (!loading()) {
	<button
		class="custom-button {{ type }} {{ color }}"
		[title]="title"
		[class.loading-inside]="loadingInside()"
		[class.fill]="fill"
		[class.selected]="active()"
		[class.font-size-normal]="!fontSizeMd && !fontSizeLg"
		[class.font-size-md]="fontSizeMd"
		[class.font-size-lg]="fontSizeLg"
		[class.no-wrap]="oneLine()"
		#button
		matRipple>
		@if (loadingInside()) {
			<mat-spinner diameter="22" strokeWidth="3" />
		} @else {
			@if (icon) {
				@defer (on timer(1)) {
					<icon [name]="icon" [size]="iconSize" [customColor]="getTextColor()" />
				}
			}
			{{ label | translate }}
			<ng-content></ng-content>
		}
	</button>
} @else {
	<div class="loading {{ loadingClass }}">
		<div class="item height button border-radius-btn" [class.width-100]="fill"></div>
	</div>
}

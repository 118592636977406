import { inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map } from 'rxjs';
import { SitesHttpService } from '@core/http/sites-http.service';
import { SiteModel } from '@shared/models/';
import { ISiteType } from '@shared/interfaces';
import { AppService } from '@shared/services/app.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { AppStorageService } from '@shared/services/app-storage.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root',
})
export class SitesService {
    private httpSites: SitesHttpService = inject(SitesHttpService);
    private sitesHttp: SitesHttpService = inject(SitesHttpService);
    private appService: AppService = inject(AppService);
    private router: Router = inject(Router);
    private appStorage: AppStorageService = inject(AppStorageService);

    private readonly sites$: BehaviorSubject<SiteModel[]> = new BehaviorSubject<SiteModel[]>([]);
    private readonly selectedSite$: BehaviorSubject<SiteModel | null> =
        new BehaviorSubject<SiteModel | null>(null);

    readonly sitesObservable$ = this.sites$.asObservable();
    readonly selectedSiteObservable$ = this.selectedSite$.asObservable();

    private readonly STORAGE_NAME: string = environment.localStorageSuffix + '_SITE';

    private _loading: WritableSignal<boolean> = signal(true);
    private _selectedSites: WritableSignal<SiteModel[]> = signal([]);
    private _filteredSites: WritableSignal<SiteModel[]> = signal([]);
    private _multiple: WritableSignal<boolean> = signal(false);
    private _siteLanguage: WritableSignal<string> = signal('en');
    private _siteTypes: WritableSignal<ISiteType[]> = signal([
        { type: 'customer', name: 'acm_customer', helper: 'acm_customer_helper' },
        {
            type: 'customerInactive',
            name: 'acm_customer_inactive',
            helper: 'acm_customer_inactive_helper',
        },
        { type: 'pilot', name: 'acm_pilot', helper: 'acm_pilot_helper' },
        { type: 'demo', name: 'acm_demo', helper: 'acm_demo_helper' },
    ]);

    loading: Signal<boolean> = this._loading.asReadonly();
    selectedSites: Signal<SiteModel[]> = this._selectedSites.asReadonly();
    filteredSites: Signal<SiteModel[]> = this._filteredSites.asReadonly();
    multiple: Signal<boolean> = this._multiple.asReadonly();
    siteLanguage: Signal<string> = this._siteLanguage.asReadonly();
    siteTypes: Signal<ISiteType[]> = this._siteTypes.asReadonly();
    sites: Signal<SiteModel[]> = toSignal(this.sitesObservable$, { initialValue: [] });
    site: Signal<SiteModel | null> = toSignal(this.selectedSiteObservable$.pipe(), {
        initialValue: null,
    });
    siteName: Signal<string> = toSignal(
        this.selectedSiteObservable$.pipe(map(site => site?.name || '')),
        { initialValue: '' },
    );
    sitePilot: Signal<boolean> = toSignal(
        this.selectedSiteObservable$.pipe(map(site => site?.pilotSite || false)),
        { initialValue: false },
    );
    siteReal: Signal<boolean> = toSignal(
        this.selectedSiteObservable$.pipe(map(site => site?.realSite || false)),
        { initialValue: false },
    );
    countries: Signal<string[]> = toSignal(
        this.sitesObservable$.pipe(
            map((sites: SiteModel[]) => {
                return sites
                    .filter(site => !!site.address.country)
                    .reduce((countries: string[], site: SiteModel) => {
                        if (countries.indexOf(site.address.fullCountry) <= -1)
                            countries.push(site.address.fullCountry);
                        return countries;
                    }, []);
            }),
        ),
        { initialValue: [] },
    );

    async fnInit(): Promise<void> {
        await this.getSites();
        if (this.sites().length) {
            const siteId: string = this.appStorage.getItem(this.STORAGE_NAME);
            this.selectedSite = this.getSiteById(siteId) || this.sites()[0];
        }
    }

    async getSites(skipLoading: boolean = false): Promise<void> {
        if (!skipLoading) this.startLoading();
        this.updateSites(await lastValueFrom(this.httpSites.getSites()));
        // this.sites = [];
        if (!this.sites().length) {
            this.appService.hasError();
            this.router.navigate(['error/sites']).then();
        }
        if (!skipLoading) this.stopLoading();
    }

    updateSites(value: SiteModel[]) {
        this.sites$.next(value);
    }

    set selectedSite(value: SiteModel | null) {
        this.appStorage.setItem(environment.localStorageSuffix + '_SITE', value?._id);
        this.selectedSite$.next(value);
        this._siteLanguage.set(value?.languageCode || 'en');
    }

    get selectedSite(): SiteModel {
        return this.selectedSite$.getValue() as SiteModel;
    }

    startLoading(): void {
        this._loading.set(true);
    }

    stopLoading(): void {
        this._loading.set(false);
    }

    setFilteredSites(value: SiteModel[] = this.sites()) {
        this._filteredSites.set(value);
    }

    setSelectedSites(value: SiteModel[]) {
        this._selectedSites.set(value);
    }

    allowMultipleSelection(): void {
        this._multiple.set(true);
    }

    disallowMultipleSelection(): void {
        this._multiple.set(false);
    }

    changeSite(siteId: string): void {
        const site: SiteModel | undefined = this.getSiteById(siteId);
        if (site) this.selectedSite = site;
    }

    getSiteById(siteId: string): SiteModel | undefined {
        return this.sites().find(site => site._id == siteId);
    }

    filterSites(sites: string[]) {
        this._filteredSites.set(this.sites().filter(site => sites.indexOf(site._id) > -1));
        this.setSelectedSites(this.sites().filter(site => sites.indexOf(site._id) > -1));
    }

    allowSync(site: SiteModel | null | undefined) {
        if (!site) return true;

        // FM-2020
        if (!site?.koneId) return false;
        return site?.apf != site?.koneId;
    }

    deleteSite(id: string): void {
        this.sitesHttp.deleteSite(id).subscribe(site => {
            // console.log('site successfully delete');
        });
    }
}

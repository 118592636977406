import { Component, inject, signal, WritableSignal } from '@angular/core';
import { environment } from '@env/environment';
import { FormHelperGroupModel } from '@shared/models/form-helper';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { AppStarterService } from '@shared/services';
import { CustomCardComponent } from '@shared/components/custom-card';
import { GlassEffectDirective } from '@shared/directives';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AlertBalloonComponent } from '@shared/components/alert-balloon/alert-balloon.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { IAuthForm } from '@app/pages/auth/interfaces';
import { AuthHeaderComponent } from '@shared/components/auth-header/auth-header.component';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { signIn, signInWithRedirect } from 'aws-amplify/auth';
import { Router } from '@angular/router';
import { translations } from '@shared/utils/translations';

@Component({
    standalone: true,
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    imports: [
        TranslateModule,
        CustomCardComponent,
        GlassEffectDirective,
        AuthHeaderComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        AlertBalloonComponent,
        MatProgressSpinnerModule,
        MatInputModule,
        CustomButtonComponent,
    ],
    styleUrls: ['../../auth.component.scss'],
})
export class SignInComponent {
    private appStarterService: AppStarterService = inject(AppStarterService);
    private router: Router = inject(Router);

    applicationName: string = environment.name;
    loadingCognito: boolean = false;

    alertMsg: string = '';
    formGroup: FormHelperGroupModel<IAuthForm> = new FormHelperGroupModel();

    forgotPassword(): void {
        this.router
            .navigate(['auth/forgot-password/' + this.formGroup.getValue('username')])
            .then();
    }

    loading: WritableSignal<boolean> = signal(false);

    constructor() {
        this.formGroup.build({
            username: '',
            password: '',
        });

        this.formGroup.setMandatoryFields(['username', 'password']);
        this.formGroup.setValidators(['username'], Validators.email);
    }

    goBack(): void {
        this.formGroup.enableFields('username');
        this.alertMsg = '';
    }

    sso(): void {
        this.loadingCognito = true;
        signInWithRedirect({
            provider: {
                custom: environment.aws.provider,
            },
        });
    }

    login(): void {
        if (this.loading() || this.loadingCognito) return; // while loading ignore any attempt to login again

        this.formGroup.form.markAllAsTouched();

        if (this.formGroup.invalid()) return;

        if (this.koneEmail(this.formGroup.getRawValue().username)) {
            this.alertMsg = translations.auth.error.kone_email;
            return;
        }

        this.alertMsg = '';
        this.loading.set(true);

        signIn({
            username: this.formGroup.getRawValue().username,
            password: this.formGroup.getRawValue().password,
        }).then(
            async () => {
                const loggedIn = await this.appStarterService.initUser(true);
                if (!loggedIn) {
                    this.alertMsg = translations.acm_login_error;
                    this.loading.set(false);
                }
            },
            error => {
                this.alertMsg = error.message ?? translations.acm_login_error;
                this.loading.set(false);
            },
        );
    }

    koneEmail(email: string): boolean {
        const pattern = /^[a-z]+\.[a-z]+@kone\.com$/i;
        return pattern.test(email);
    }
}

export const environment = {
	production: false,
	name: 'Elevator Call Manager',
	localStorageSuffix: 'kecm',
	mockService: false,
	api: 'https://qa.resiflow.dps.kone.com/api/kecmgr',
	qrCodeUrl: 'https://keca-onboard-qa-dps.kone.com',
	aws: {
		aws_project_region: 'eu-west-1',
		aws_cognito_region: 'eu-west-1',
		aws_user_pools_id: 'eu-west-1_uOiglDK2i',
		aws_user_pools_web_client_id: '25tnjk7b7s8fnpk99ht6vr3fdo',
		oauth: {
			domain: 'resiflow-qa.auth.eu-west-1.amazoncognito.com',
			scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
			redirectSignIn: 'https://kecmgr-qa-dps.kone.com/',
			redirectSignOut: 'https://kecmgr-qa-dps.kone.com/',
			responseType: 'code',
		},
		provider: 'KONE-AD-SSO-qa-qa-IdP',
	},
};

import { Pipe, PipeTransform } from '@angular/core';
import { TUserData } from '@shared/interfaces';
import { RoleModel } from '@modules/user-management/models';

@Pipe({
	standalone: true,
	name: 'roleDescription',
})
export class RoleDescriptionPipe implements PipeTransform {
	transform(type: TUserData, roles: RoleModel[]): string {
		return roles.find(r => r.type == type)?.description ?? '';
	}
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormHelperGroupModel } from '@shared/models/form-helper';
import { AbstractControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { IPasswordConfirmationForm } from '@app/pages/auth/interfaces';

@Component({
    standalone: true,
    selector: 'password-confirmation',
    template: `
        <form [formGroup]="formGroup.form" class="width-100">
            <!-- PASSWORD -->
            <mat-form-field appearance="outline" class="item-margin-top">
                <mat-label>{{ 'acm_password' | translate }}</mat-label>
                <input
                    type="password"
                    matInput
                    autocomplete="new-password"
                    [formControlName]="formGroup.getControlName('password')" />
                @if (
                    !formGroup.validType('password', 'minlength') &&
                    formGroup.validType('password', 'pattern')
                ) {
                    <mat-error>{{ 'acm_password_requirements' | translate }}</mat-error>
                }
                @if (formGroup.validType('password', 'minlength')) {
                    <mat-error>{{ 'acm_password_length_required' | translate }}</mat-error>
                }
                @if (formGroup.validType('password', 'required')) {
                    <mat-error>{{ 'acm_field_mandatory' | translate }}</mat-error>
                }
            </mat-form-field>

            <!-- PASSWORD REPEAT -->
            <mat-form-field appearance="outline" class="item-margin-top">
                <mat-label>{{ 'acm_repeat_password' | translate }}</mat-label>
                <input
                    type="password"
                    matInput
                    autocomplete="off"
                    [formControlName]="formGroup.getControlName('confirmPassword')" />
                @if (
                    formGroup.getValue('confirmPassword') &&
                    formGroup.validType('confirmPassword', 'passwordMismatch')
                ) {
                    <mat-error>{{ 'acm_password_miss_match' | translate }}</mat-error>
                }
                @if (formGroup.validType('confirmPassword', 'required')) {
                    <mat-error>{{ 'acm_field_mandatory' | translate }}</mat-error>
                }
            </mat-form-field>
        </form>
    `,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
    ],
    styleUrls: ['../../auth.component.scss'],
})
export class PasswordConfirmationComponent implements OnInit {
    @Output() newPassword: EventEmitter<string> = new EventEmitter<string>();

    formGroup: FormHelperGroupModel<IPasswordConfirmationForm> = new FormHelperGroupModel();
    passwordRegex: string =
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&-_/#$.])[A-Za-z\\d@$!%*?&-_/#$.]{8,}$';

    ngOnInit(): void {
        this.formGroup.build({
            password: '',
            confirmPassword: '',
        });

        this.formGroup.setMandatoryFields(['password', 'confirmPassword']);

        //validators
        this.formGroup.setValidators(
            ['password'],
            [Validators.minLength(8), Validators.pattern(this.passwordRegex)],
        );
        this.formGroup.setValidators(
            ['confirmPassword'],
            [this.passwordsMatchValidator(this.formGroup)],
        );

        this.formGroup.changes().subscribe(value => {
            if (
                this.formGroup.valid() &&
                !!this.formGroup.getValue('password') &&
                !!this.formGroup.getValue('confirmPassword')
            ) {
                this.newPassword.emit(this.formGroup.getValue('password'));
            } else {
                this.newPassword.emit('');
            }
        });
    }

    passwordsMatchValidator(formGroup: FormHelperGroupModel<IPasswordConfirmationForm>) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const password = formGroup.getValue('password');
            const confirmPassword = formGroup.getValue('confirmPassword');
            if (password !== confirmPassword) {
                return { passwordMismatch: true };
            }
            return null;
        };
    }
}

import { IRole, IRoleDto } from '@modules/user-management/interfaces';
import { RoleRightModel } from '@modules/user-management/models';
import { GenericModel } from '@shared/models/generic.model';

export class RoleModel extends GenericModel<IRole, IRoleDto> implements IRole {
	description: string;
	id: string;
	name: string;
	type: string;
	readonly?: boolean;
	rights: RoleRightModel[];

	constructor(role: IRoleDto | null = null) {
		super(role, RoleModel);

		this.name = role?.name || '';
		this.type = role?.type || '';
		this.description = role?.description || '';
		this.readonly = role?.readonly || false;
		this.rights = role?.rights.map(r => new RoleRightModel(r)) || [];
		this.id = role?.id.toString() || new Date().getTime().toString();
	}

	dataTransferObject(): IRoleDto {
		return {
			...this.getOriginalData(),
			name: this.name,
			description: this.description,
			rights: this.rights.map(r => r.dataTransferObject()),
		};
	}
}

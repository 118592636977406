import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { AppRouteManagementService } from '@shared/services/app-route-management.service';
import { IRouteAccessConfig, TPageType } from '@shared/models//route-management.model';
import { TranslateService } from '@ngx-translate/core';
import frontlinesData from '@assets/json/frontline.json';
import {
    FrontLineModel,
    RoleRightModel,
    UserManagementModel,
    RoleModel,
} from '@modules/user-management/models';
import {
    TUser,
    IFrontLine,
    IRoleRightDto,
    IRoleDto,
    IUserManagementDto,
} from '@modules/user-management/interfaces';
import { environment } from '@env/environment';

const ENDPOINT = environment.api;

@Injectable({
    providedIn: 'root',
})
export class UserManagementHttpService {
    private http: HttpClient = inject(HttpClient);
    private snackBar: SnackBarService = inject(SnackBarService);
    private routeManagement: AppRouteManagementService = inject(AppRouteManagementService);
    private translate: TranslateService = inject(TranslateService);

    roleRights: IRoleRightDto[] = Object.keys(this.routeManagement.routeAccess).map(
        (key, index) => {
            const page: TPageType = key as TPageType;
            const config: IRouteAccessConfig = this.routeManagement.routeAccess[page];
            return {
                id: (index + 1).toString(),
                setting: this.translate.instant(config.title),
                view: false,
                manage: false,
            };
        },
    );

    roles: IRoleDto[] = [
        {
            id: 1,
            type: 'globalAdmin',
            name: 'KONE Global Admin',
            description:
                'The Administrator role is the highest level of access within the system, providing full control and management capabilities. Users assigned to this role have the authority to configure settings, manage user accounts, grant permissions, and perform administrative tasks across the entire system.',
            readonly: true,
            rights: this.roleRights.map(role => {
                return {
                    ...role,
                    view: true,
                    manage: true,
                };
            }),
        },
        {
            id: 2,
            type: 'technical',
            name: 'KONE Technical',
            description:
                'The Technical role is the standard role for majority of KONE Employees. Users assigned to this role have the authority to manage and configure sites, access groups and users at same level as KONE Global Admin role, in addition to create new sites.',
            readonly: true,
            rights: this.roleRights,
        },
        {
            id: 3,
            type: 'viewer',
            name: 'KONE Viewer',
            description:
                'The viewer role is meant for employees performing auditing activities, without need to configure or manage the solution. Users assigned to this role have the authority to view all data and features and their configurations.',
            readonly: true,
            rights: this.roleRights.map((role, index) => {
                return {
                    ...role,
                    view: true,
                };
            }),
        },
        {
            id: 4,
            type: 'facilityManager',
            name: 'Facility Manager',
            description:
                'Standard role for Customer Facility Managers providing highest level of access required to manage Elevator Call Sites. Users assigned to this role have the authority to configure sites and/or create access groups and users.',
            readonly: true,
            rights: this.roleRights,
        },
    ];

    users: IUserManagementDto[] = [];

    setUsers(): void {
        for (let i = 1; i <= 20; i++) {
            const roles: IRoleDto[] = [...this.roles];
            const types: string[] = ['customer', 'kone'];
            const randomTypeIndex = this.getRandomInt(0, types.length - 1);
            const numRoles = this.getRandomInt(1, 3); // Random number between 1 and 3

            // Shuffle the roles array
            for (let j = roles.length - 1; j > 0; j--) {
                const randomIndex = this.getRandomInt(0, j);
                [roles[j], roles[randomIndex]] = [roles[randomIndex], roles[j]];
            }

            const userRoles: IRoleDto[] = roles.slice(0, numRoles); // Select the first numRoles elements

            const type: TUser = types[randomTypeIndex] as TUser;
            this.users.push({
                id: i.toString(),
                firstName: `User ${i}`,
                lastName: `Last Name`,
                email: `user${i}@kone.com`,
                type: type,
                roles: userRoles.map(role => role.id || 1),
                dataAccess: {
                    frontlines: type == 'kone' ? ['KEF', 'KOP', 'EGG'] : [],
                    sites:
                        type == 'customer'
                            ? [
                                  '6182613f02aa49001395e8a1',
                                  '639c2d973e8a8e827a8a0be9',
                                  '616401b8e1d27000135c3b63',
                              ]
                            : [],
                },
                status: i % 5 === 0 ? 'inactive' : 'active',
                phoneNumber: '',
                phone: {
                    countryCode: '',
                    number: '',
                },
                country: '',
                language: '',
            });
        }
    }

    getRandomInt(min: number, max: number): number {
        const range = max - min + 1;
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        return min + (array[0] % range);
    }

    constructor() {
        this.setUsers();
    }

    getUsers(): Observable<UserManagementModel[]> {
        return this.http.get<IUserManagementDto[]>(ENDPOINT + '/manage/users').pipe(
            map((users: IUserManagementDto[]) => {
                try {
                    return users.map(u => new UserManagementModel(u));
                } catch (e) {
                    throw new Error('getUsers');
                }
            }),
            catchError(this.handleError('getUsers', [])),
        );
    }

    getUser(userId: string): Observable<UserManagementModel | undefined> {
        return this.getUsers().pipe(
            map(users => {
                return users.find(u => u.id == userId);
            }),
            map(user => (user ? user : undefined)),
        );
    }

    getRoles(): Observable<RoleModel[]> {
        return of(this.roles).pipe(
            map(userRoles => {
                return userRoles.map(ur => new RoleModel(ur)) || [];
            }),
            delay(700),
        );
    }

    getRoleRights(): Observable<RoleRightModel[]> {
        return of(this.roleRights).pipe(
            map(roles => {
                return roles.map((r: IRoleRightDto) => new RoleRightModel(r));
            }),
            delay(700),
        );
    }

    addUser(user: UserManagementModel): Observable<UserManagementModel> {
        const uOB = user.dataTransferObject();
        this.users.unshift(uOB);
        return this.http
            .post(ENDPOINT + '/manage/users', uOB)
            .pipe(map(userDto => new UserManagementModel(<IUserManagementDto>userDto)));
    }

    updateUser(user: UserManagementModel) {
        const index: number = this.users.findIndex(u => u.id == user.id);
        this.users[index] = user.dataTransferObject();

        return this.http
            .put<IUserManagementDto>(ENDPOINT + '/manage/users', user.dataTransferObject())
            .pipe(
                map(userDto => new UserManagementModel(userDto)),
                catchError(this.handleError('updateUser', 'error')),
            );
    }

    deleteUser(id: string): Observable<string> {
        const index: number = this.users.findIndex(u => u.id == id);
        this.users.splice(index, 1);
        return new Observable<string>(observer => {
            observer.next();
            observer.complete();
        }).pipe(delay(600));
    }

    createRole(role: RoleModel) {
        this.roles.push(role.dataTransferObject());

        return new Observable<IRoleDto>(observer => {
            observer.next(role.dataTransferObject());
            observer.complete();
        }).pipe(
            delay(600),
            map(roleDto => new RoleModel(roleDto)),
        );
    }

    updateRole(role: RoleModel): Observable<RoleModel | string> {
        const index: number = this.roles.findIndex(u => u.id.toString() == role.id);
        this.roles[index] = role.dataTransferObject();

        return new Observable<RoleModel>(observer => {
            observer.next(role);
            observer.complete();
        }).pipe(
            delay(600),
            map(r => r),
        );
    }

    handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            if (error.status === 403) this.snackBar.error('acm_forbidden');
            else if ([500, 503].indexOf(error.status) > -1)
                this.snackBar.error('acm_service_not_available');
            else if (operation === 'getUsers') this.snackBar.error('acm_users_not_found');
            else if (operation === 'updateUser')
                this.snackBar.error('acm_update_user_management_error');
            else this.snackBar.error('acm_something_went_wrong');

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}

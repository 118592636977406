import { Component, effect, inject } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { CustomCardComponent } from '@shared/components/custom-card';
import { AuthHeaderComponent } from '@shared/components/auth-header/auth-header.component';
import { GlassEffectDirective } from '@shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { AppRouteManagementService, AppService } from '@shared/services';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { SitesService } from '@shared/services/sites.service';
import { Router } from '@angular/router';

@Component({
    standalone: true,
    selector: 'app-sites-unavailable',
    templateUrl: './sites-unavailable.component.html',
    imports: [
        TranslateModule,
        CustomCardComponent,
        AuthHeaderComponent,
        IconComponent,
        GlassEffectDirective,
        CustomButtonComponent,
    ],
    styleUrls: ['../../error.component.scss'],
})
export class SitesUnavailableComponent {
    private appService: AppService = inject(AppService);
    private router: Router = inject(Router);
    private appRouteManagementService = inject(AppRouteManagementService);

    sites = inject(SitesService).sites;

    constructor() {
        effect(() => {
            if (this.sites().length)
                this.router.navigate([this.appRouteManagementService.defaultRoute]);
        });
    }

    redirectToLogin(): void {
        this.appService.logout();
    }
}

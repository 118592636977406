import { GenericModel } from '@shared/models/generic.model';
import { IUserDataAccess, IUserDataAccessDto } from '@shared/interfaces/user-data-access.interface';

export class UserDataAccessModel
    extends GenericModel<IUserDataAccess, IUserDataAccessDto>
    implements IUserDataAccess
{
    frontlines: string[];
    sites: string[];

    constructor(dataAccess: IUserDataAccessDto | null = null) {
        super(dataAccess, UserDataAccessModel);

        this.frontlines = dataAccess?.frontlines ?? [];
        this.sites = dataAccess?.sites ?? [];
    }

    dataTransferObject(): IUserDataAccessDto {
        return {
            ...this.getOriginalData(),
            frontlines: this.frontlines,
            sites: this.sites,
        };
    }
}

import { Component, inject, Input, signal, WritableSignal } from '@angular/core';
import { FormHelperGroupModel } from '@shared/models/form-helper';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomCardComponent } from '@shared/components/custom-card';
import { AlertBalloonComponent } from '@shared/components/alert-balloon/alert-balloon.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { GlassEffectDirective } from '@shared/directives';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { IForgotPasswordForm } from '@app/pages/auth/interfaces';
import { PasswordConfirmationComponent } from '@app/pages/auth/components/password-confirmation';
import { AuthHeaderComponent } from '@shared/components/auth-header/auth-header.component';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { TColor } from '@shared/interfaces';

@Component({
    standalone: true,
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    imports: [
        CustomCardComponent,
        AuthHeaderComponent,
        AlertBalloonComponent,
        MatFormFieldModule,
        ReactiveFormsModule,
        TranslateModule,
        GlassEffectDirective,
        MatProgressSpinnerModule,
        MatInputModule,
        PasswordConfirmationComponent,
        CustomButtonComponent,
    ],
    styleUrls: ['../../auth.component.scss'],
})
export class ForgotPasswordComponent {
    private router: Router = inject(Router);

    @Input()
    set email(email: string) {
        this.username = email;
        resetPassword({ username: email }).then(value => {
            this.formGroup.setValue('username', email);
            this.formGroup.enable();
            this.forgotMsg = 'acm_forgot_msg_success';
        });
    }

    username: string = '';
    forgotMsg: string = '';
    alertMsg: string = '';
    passwordChanged: boolean = false;
    alertColor: TColor;
    formGroup: FormHelperGroupModel<IForgotPasswordForm> = new FormHelperGroupModel();

    loading: WritableSignal<boolean> = signal(false);

    constructor() {
        this.formGroup.build({
            username: '',
            code: undefined,
            password: '',
        });

        this.formGroup.disable();

        this.formGroup.setMandatoryFields(['username', 'password', 'code']);
    }

    goBack(): void {
        this.router.navigate(['auth/signin/']).then();
    }

    forgotPassword(): void {
        if (this.loading()) return;

        this.formGroup.form.markAllAsTouched();

        if (this.formGroup.invalid()) return;

        this.alertMsg = '';
        this.loading.set(true);

        confirmResetPassword({
            username: this.formGroup.getValue('username'),
            newPassword: this.formGroup.getValue('password'),
            confirmationCode: this.formGroup.getValue('code')!.toString(),
        }).then(
            result => {
                this.alertColor = 'success';
                this.alertMsg = 'acm_password_change_success';
                this.passwordChanged = true;
                this.loading.set(false);
            },
            error => {
                this.alertColor = 'warn';
                this.alertMsg = error.message ?? 'acm_login_error';
                this.loading.set(false);
            },
        );
    }
}
